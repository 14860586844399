/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #fff;
}

h1 {
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  margin: 0 0 8px
}

.hazmat-card {
  background-color: whitesmoke !important;
  min-height: 575px;
}

.center {
  text-align: justify;
  padding-left: 30px
}

.hazmat-form-full-width {
  padding-right: 20px
}

.productno, .orderno {
  color: black;
}

.mat-card-header-text {
  margin: 0px !important;
}

.mat-card-title {
  font-size: 16px !important;
}

.matcard {
  margin-top: 8px;
  margin-right: 25px;
  cursor: pointer;
  height: 85px;
}

  .matcard ul {
    padding-left: 0px;
    margin-top: 30px;
    margin-bottom: 5px;
  }

    .matcard ul li {
      display: inline-block;
      padding-right: 10px;
    }

      .matcard ul li i {
        padding-right: 3px;
      }


  .matcard:hover {
    background-color: #e5f2ff;
  }


table {
  width: 100%;
}

  table th {
    background-color: #e5f2ff;
    font-weight: bold !important;
  }



.mat-form-field-ripple {
  background-color: transparent !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #700100 !important;
}

.spinner-alignment {
  margin-top: 50px;
  position: absolute !important;
  z-index: 1;
  left: 40%;
}

.snackbar {
  max-width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 1rem !important;
  padding: 10px !important;
 /* background-color: black;*/
  color: #f7f0cf;
}


label {
  cursor: default;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}
.pull-right {
  float: right !important;
}
.btn-default {
  text-shadow: 0 1px 0 #fff;
  background-image: -webkit-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: -o-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e0e0e0));
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #ccc;
}
